@import '~antd/dist/antd.css';

html, body {
  font-family: "Outfit", sans-serif;
}
.noscroll {
  overflow: hidden;
  height: 100%;
  touch-action: none;
}
.layout header.MuiAppBar-root {
  padding-right: 0 !important;
}
#main-content {
  background-color: #E0E5ED;
  z-index: 1;
  padding-left: 0;
  padding-top: 8px;
}
.RaMenuItemLink-icon {
  min-width: 35px !important;
}
.ScrollbarsCustom-Track {
  width: 5px !important;
  right: -7px !important;
}
.capitalized {
  display: inline-block;
}
.capitalized:first-letter {
  text-transform: uppercase;
}

/* List */
.RaList-main {
  padding: 15px 0 15px 44px;
  position: relative;
}
.RaList-main .RaFilter-form {
  margin-top: 3px;
}
.RaList-main .RaFilter-button {
  margin-right: auto;
}
.RaList-main > .MuiToolbar-root { /* Toolbar filters and actions (top bar) */
  margin: 15px 0 25px;
  align-items: flex-start;
  max-width: calc(100vw - 293px);
}
.RaList-main > .MuiToolbar-root > .MuiToolbar-root { /* Toolbar actions (top right) */
  padding: 20px 0 0;
  min-height: auto;
  flex: 1;
}
.MuiTooltip-tooltip svg[data-testid="KeyboardArrowRightIcon"],
.MuiTooltip-tooltip svg[data-testid="KeyboardArrowDownIcon"] {
  margin-bottom: -8px;
  margin-left: 5px;
}
form[class$=RaFilterForm-root] {
  align-items: flex-start !important;
}

/* Table */
.tableSentered td {
  text-align: center;
}
.RaDatagrid-tableWrapper table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px !important;
  border-style: hidden;
  background-color: white;
}
.RaDatagrid-tableWrapper table thead tr {
  background-color: #E1E5ED;
}
.RaDatagrid-tableWrapper .MuiTableCell-paddingCheckbox {
  padding: 0 12px 0 14px !important;
  text-align: center !important;
}
.RaDatagrid-tableWrapper .MuiTableCell-root {
  padding: 7px 10px;
  min-height: 35px;
  color: #74706B;
  font-family: Outfit, sans-serif;
  font-size: 11px;
  line-height: 12px;
  font-style: normal;
  font-weight: 400;
}
.RaDatagrid-tableWrapper .MuiTableCell-root .MuiTypography-root {
  font-size: 11px;
  line-height: 12px;
}
.RaDatagrid-tableWrapper td {
  border-bottom: 1px solid #042E6B !important;
  border-right: 1px solid #042E6B !important;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root {
  padding: 10px;
  color: #042E6B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  background-color: #E1E5ED;
  border-top: 1px solid #042E6B;
  border-bottom: 1px solid #042E6B;
  border-right: 1px solid #042E6B;
  position: static;
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-fullName,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-address,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-email,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-userId,
.RaDatagrid-tableWrapper th.MuiTableCell-root[class~="column-user.fullName"],
.RaDatagrid-tableWrapper th.MuiTableCell-root[class~="column-user.address"],
.RaDatagrid-tableWrapper th.MuiTableCell-root[class~="column-user.email"] {
  min-width: 215px;
  max-width: 215px;
  width: 215px;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-code,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-createdAt,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-updatedAt,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-isCompany,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-bithday,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-contactBy,
.RaDatagrid-tableWrapper th.MuiTableCell-root.column-contactAt {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root.MuiTableCell-paddingCheckbox {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}
.RaDatagrid-tableWrapper .column-credit\.id,
.RaDatagrid-tableWrapper .column-createdAt,
.RaDatagrid-tableWrapper .column-updatedAt,
.RaDatagrid-tableWrapper .column-paymentAt,
.RaDatagrid-tableWrapper .column-credit\.paymentAt,
.RaDatagrid-tableWrapper .column-modificationAt,
.RaDatagrid-tableWrapper .column-credit\.modificationAt,
.RaDatagrid-tableWrapper .column-signatureAt,
.RaDatagrid-tableWrapper .column-credit\.signatureAt,
.RaDatagrid-tableWrapper .column-phone,
.RaDatagrid-tableWrapper .column-user\.phone,
.RaDatagrid-tableWrapper .column-contactBy {
  text-align: center;
}

.RaDatagrid-tableWrapper .RaDatagrid-headerRow th {
  position: sticky;
  z-index: 1;
  border-right: none !important;
}
.RaDatagrid-tableWrapper .RaDatagrid-headerRow th:last-child {
  border-right: 1px solid #042E6B !important;
}
.RaDatagrid-tableWrapper .RaDatagrid-headerRow th:before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: -1px;
  border-right: 1px solid #042E6B;
}
.RaDatagrid-tableWrapper .RaDatagrid-headerRow th:first-child:before {
  display: none;
}

.noscroll .RaDatagrid-tableWrapper .RaDatagrid-headerRow th {
  position: static !important;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root:first-child {
  border-top-left-radius: 10px !important;
}
.RaDatagrid-tableWrapper th.MuiTableCell-root:last-child {
  border-top-right-radius: 10px !important;
}
.RaDatagrid-tableWrapper table th:first-child,
.RaDatagrid-tableWrapper table td:first-child,
.RaList-main table td:first-child {
  border-left: 1px solid #042E6B;
  text-align: center;
}
.RaDatagrid-tableWrapper tr:last-child td:first-child {
  border-bottom-left-radius: 10px !important;
}
.RaDatagrid-tableWrapper tr:last-child td:last-child {
  border-bottom-right-radius: 10px !important;
}
.comment-check {
  padding: 0 !important;
}

/* List Create/Export/Filter buttons */
.RaFilter-form .MuiSelect-multiple {
  padding: 2px 16px 5px 4px !important;
  min-height: 29px !important;
}
.RaFilter-form .MuiButtonBase-root, div[class$="RaTopToolbar-root"] .MuiButtonBase-root {
  height: 37px;
  padding: 7px 10px;
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  font-size: 14px;
  color: #042E6B;
  font-weight: 400;
  position: relative;
  text-transform: none;
  overflow: unset;
  width: auto;
  min-width: unset;
}
.RaFilter-form .MuiCheckbox-root {
  background-color: transparent !important;
  border: none !important;
}
.RaFilter-form .MuiButtonBase-root:hover, div[class$="RaTopToolbar-root"] .MuiButtonBase-root:hover {
  background: #e8e8e8;
}
.RaList-main .MuiButtonBase-root[aria-label="Export"] svg {
  color: transparent;
}
.RaList-main .MuiButtonBase-root[aria-label="Export"]::after {
  content: '';
  position: absolute;
  margin-top: -10px;
  top: 50%;
  left: 10px;
  width: 16px;
  height: 20px;
  background: url("/public/icons/export.svg") no-repeat top left;
}
.RaList-main .MuiButtonBase-root[aria-label="Add filter"] {
  position: relative;
  padding-left: 45px !important;
  width: 120px;
  overflow: hidden;
}
.RaList-main .MuiButtonBase-root[aria-label="Add filter"] .MuiButton-startIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #042E6B;
}
.RaList-main .MuiButtonBase-root[aria-label="Add filter"] svg {
  font-size: 26px;
  color: white;
}
.RaList-main .MuiButtonBase-root[aria-label="Add filter"]:hover svg {
  color: #e8e8e8;
}
.RaEdit-noActions {
  margin-top: 0 !important;
}

/* Toolbar Filters */
.RaList-main .RaFilter-form .MuiInputBase-root {
  margin-top: 0 !important;
  border-radius: 8px;
  border: 1px solid #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.RaList-main .RaFilter-form .MuiInputBase-root::before,
.RaList-main .RaFilter-form .MuiInputBase-root::after {
  display: none;
}
.RaList-main .RaFilter-form .MuiInputBase-root fieldset {
  border: none;
}
.RaList-main .RaFilter-form .MuiSelect-select {
  border-radius: 8px;
  padding: 8px 14px 6px;
}
.MuiInputBase-root .MuiSelect-icon {
  right: -6px !important;
}
.MuiChip-root {
  margin: 1px 0;
  font-size: 11px !important;
  height: 24px !important;
  max-width: 290px !important;
}
.MS-field .MuiSelect-multiple {
  padding: 5px 14px !important;
  min-height: 26px !important;
}
.MS-field .MuiSelect-multiple .MuiChip-root, .MS-field .MuiSelect-select .MuiChip-root {
  margin-top: 1px;
  margin-bottom: 1px;
}
.MS-field .RaSelectArrayInput-chips {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: block !important;
}
.RaList-main .RaFilter-form .MuiInputBase-root input {
  border-radius: 8px;
  padding: 7px 14px;
}
.RaList-main .RaFilter-form .MuiFormLabel-root {
  color: rgba(0, 42, 119, 0.50);
  font-size: 14px;
  padding: 0;
  transform: none;
  left: 14px;
  bottom: 8px;
  top: unset;
  z-index: 0;
}
.RaList-main .RaFilter-form .MuiInputLabel-shrink {
  color: #042E6B !important;
  bottom: unset;
  top: -15px;
  font-size: 12px;
  line-height: 12px;
}
.RaList-main .RaFilter-form .RaFilterFormInput-hideButton {
  display: none;
}
.RaList-main .RaFilter-form .MuiFormControl-root {
  margin-top: 18px !important;
  background: white;
  border-radius: 8px;
}
.RaList-main .RaFilter-form .MuiTextField-root input {
  padding-right: 36px;
}
.RaList-main .RaFilter-form .MuiTextField-root:not([class*="RaSelectInput"]):not([class*="ra-input-activationId"]):not(.is-select)::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 13px;
  width: 15px;
  height: 15px;
  background: url("/public/icons/search.svg") no-repeat top left;
}
.RaList-main .ra-input-isDefault {
  min-width: 200px !important;
}
.RaList-main .ra-input-isGuarantor {
  min-width: 220px !important;
}

/* Table ASC/DESC sortable icon */
.RaList-main .MuiTableSortLabel-root {
  color: #042E6B !important;
}
.RaList-main .MuiTableSortLabel-root svg {
  color: #042E6B !important;
  font-size: 14px;
}
.RaList-main .MuiTableSortLabel-iconDirectionAsc::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 5px;
  background: url("/public/icons/arrow-down.svg") no-repeat top left;
}

/* Toolbar Bulk actions (Delete button) */
.RaDatagrid-root {
  position: relative !important;
}
.RaDatagrid-root > div[class^="css-"] {
  position: absolute !important;
  bottom: 0;
  left: -5px;
}
.RaBulkActionsToolbar-toolbar {
  top: 70px !important;
  height: 20px !important;
  min-height: auto !important;
  background: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: flex-start !important;
}
.RaBulkActionsToolbar-collapsed, .RaBulkActionsToolbar-title {
  display: none !important;
}
.RaBulkActionsToolbar-toolbar button {
  border-radius: 8px;
  font-size: 14px;
  text-transform: none;
  padding: 8px 12px;
}
.RaBulkActionsToolbar-toolbar button[aria-label="Delete"] {
  display: none;
}

/* Checkbox */
.MuiCheckbox-root {
  position: relative !important;
}
.MuiCheckbox-root svg {
  color: transparent !important;
}
.MuiCheckbox-root::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  width: 16px;
  height: 16px;
  background: url("/public/icons/checkbox-empty.svg") no-repeat top left;
}
.MuiCheckbox-root.Mui-checked::after {
  background: url("/public/icons/checkbox-checked.svg") no-repeat top left;
}
.RaReferenceField-link > * {
  color: #042E6B !important;
}

/* AppBar */
.RaAppBar-toolbar {
  max-width: 100vw !important;
  padding-right: 50px !important;
}

/* AppMenu */
.RaSidebar-paper .ant-badge-status {
  width: 100%;
  color: black;
}
.RaSidebar-paper .ant-badge-status sup {
  right: 25px !important;
  font-size: 11px !important;
  letter-spacing: 0 !important;
}

/* MUI pagination */
.MuiTablePagination-toolbar {
  max-width: calc(100vw - 290px);
  position: relative !important;
  color: #74706B !important;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.MuiTablePagination-displayedRows, .MuiPaginationItem-text, .MuiTablePagination-select {
  font-family: sans-serif !important;
}
.MuiTablePagination-select {
  font-size: 14px !important;
  position: relative;
}
.MuiTablePagination-select::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 8px;
  height: 5px;
  background: url("/public/icons/arrow-down.svg") no-repeat top left;
}
.MuiTablePagination-selectIcon {
  color: transparent !important;
}
.MuiPaginationItem-page {
  height: 16px !important;
  min-width: 16px !important;
  color: #74706B !important;
}
.MuiPaginationItem-page.Mui-selected {
  background: #042E6B !important;
  color: white !important;
}
.MuiPaginationItem-icon {
  color: #042E6B !important;
}
.MuiPaginationItem-ellipsis {
  color: #74706B !important;
}

/* Dropdown Menu */
.MuiModal-root .MuiMenu-paper {
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #042E6B;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.MuiMenu-paper .MuiMenu-list {
  max-width: 50vw !important;
}
.MuiMenu-paper .MuiMenu-list li {
  font-size: 14px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
  max-width: 50vw !important;
}

/* MUI */
.MuiFormControl-marginDense {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.MuiFormHelperText-marginDense, .MuiFormHelperText-root {
  margin-top: 0 !important;
}
.MuiSelect-selectMenu {
  padding-top: 3px !important;
}
.MuiTablePagination-select {
  padding-bottom: 0 !important;
}
.MuiAutocomplete-input {
  padding-top: 3px !important;
}
.RaLabeled-value-73 {
  padding: 0 0 10px !important;
}

/* MUI Checkbox */
.checkbox .MuiFormControlLabel-label {
  color: #74706B;
  line-height: 15px;
}

/* Antd */
.ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
.ant-steps-item-title {
  font-size: 15px !important;
  line-height: 15px !important;
  text-decoration: underline;
  margin-bottom: 10px;
}

/* Pagination */
.MuiTablePagination-root {
  display: flex;
}
.MuiTablePagination-root .MuiTablePagination-toolbar {
  padding-left: 0;
}
.MuiTablePagination-root p {
  margin: 0;
}

/** DateTimeInput custom */
.date-time-input .MuiFilledInput-root.Mui-disabled {
  background-color: initial !important;
}

/* Fixes */
#form-dialog-title + .MuiDialogContent-root {
  padding-top: 20px;
}

/* TextField */
.multirow .MuiSelect-select {
  white-space: break-spaces !important;
}

/* Antd Date Picker */
.ant-picker {
  padding: 0 !important;
  height: 18px !important;
  line-height: 18px !important;
  width: 100% !important;
  vertical-align: top !important;
}
.ant-picker-input {
  border-bottom: 1px solid rgb(128, 128, 128) !important;
}
.ant-form-item-control-input--without-label .ant-picker-input {
  height: 35px;
  margin-top: 12px;
}

/* Select */
.resettable-select .MuiSelect-select {
  padding-right: 50px !important;
  padding-bottom: 0;
}

/* Survey progress */
.survey-progress .ant-progress-text {
  color: #0C5541;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Reports accordion */
.accordion .MuiAccordion-root {
  box-shadow: none !important;
}
.accordion .MuiAccordion-root::before {
  background-color: rgba(4, 46, 107, 0.50) !important;
}
.accordion .MuiAccordionSummary-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.accordion .MuiAccordionSummary-content {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.datagrid-body .RaLink-link {
  color: #000;
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
  background-color: rgba(4, 46, 107, 0.05);
}

.details-page-wrapper > .RaEdit-main > .MuiPaper-root,
.details-page-wrapper > .RaCreate-main > .MuiPaper-root {
  background-color: transparent !important;
  box-shadow: initial !important;
}

/* MuiPopover */
.MuiPaper-root {
  max-height: calc(100% - 80px) !important;
  max-width: 100% !important;
}

/* State form */
.stateSelect .MuiSelect-select {
  padding: 0 !important;
}
.stateSelect fieldset {
  border: none !important;
}
.stateSelect .MuiInputBase-root {
  border-radius: 0 !important;
}

/* Page styles */
/* Page cards */
.nd-page-card {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 26px 0;
  margin-bottom: 25px;
  height: 100%;
}

.nd-page-header {
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}
