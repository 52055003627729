.wrap {
    border-radius: 0 0 8px 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 26px 32px;
}

.item {
    width: calc(50% - 13px);

    &.fullWidth {
        width: 100%;

        & .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.content {
    border-radius: 10px;
    padding: 0 21px 18px;
    border: 1px solid #042E6B;
    background: #FFF;
}

.row {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        flex-direction: row;
    }
}

.contract {
    width: 100%;

    @media (min-width: 1200px) {
        width: calc(50% - 14px);
        padding-right: 21px;
    }
}

.integration {
    width: 100%;
    padding-top: 30px;

    @media (min-width: 1200px) {
        width: calc(50% - 14px);
        padding-top: 36px;
    }

    & .section {
        padding-top: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & .actions {
        width: 100%;
        display: flex;
        padding-top: 20px;
        justify-content: center;

        @media (min-width: 1400px) {
            flex: 0 0 50%;
            max-width: 50%;
            align-items: flex-end;
        }
    }
}

.verification {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    padding: 18px 20px;

    @media (min-width: 1400px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.verificationTitle {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 24px;

    @media (min-width: 1400px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.verificationList {
    @media (min-width: 1400px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.verificationItem {
    display: flex;
    align-items: center;
}
.verificationIcon {
    padding-right: 16px;
    font-size: 20px;
}
.verificationIconValid {
    color: #042E6B;
}
.verificationIconInvalid {
    color: #FF4548;
}
.verificationInfo {
    color: #272727;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
}
.tooltip [class*="MuiTooltip-tooltip"] {
    max-width: 260px !important;
}

.genBtn {
    width: 214px!important;
    @media (min-width: 1400px) {
        width: 100%!important;
        height: 36px!important;
        max-width: 214px!important;
    }
}
