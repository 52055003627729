.form {
    margin-top: 15px;
    display: flex;
    column-gap: 40px;
    flex: 0 0 100%;
    max-width: 100%;

    @media screen and (min-width: 768px) {
        flex-wrap: wrap;
    }
    @media screen and (min-width: 1200px) {
        column-gap: 12px;
    }
    @media screen and (min-width: 1600px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
    @media screen and (min-width: 1800px) {
        flex: 0 0 190px;
        max-width: 190px;
    }
}
.check {
    @media screen and (min-width: 768px) {
        flex: 0 0 42%;
        max-width: 42%;
    }
    @media screen and (min-width: 1200px) {
        flex: 1;
        max-width: initial;
    }
    @media screen and (min-width: 1600px) {
        flex: initial;
    }
}
.warning {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 4px;

    @media screen and (min-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media screen and (min-width: 1200px) {
        flex: 2;
        max-width: initial;
    }
    @media screen and (min-width: 1600px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
