.form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.row {
  width: 100%;
  min-height: 30px;
}

.row label {
  width: 140px !important;
}

.error {
  border-bottom: 1px solid red !important;
}

.uploadBox {
  width: 400px;
  height: 210px;
  margin: 35px auto;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.uploadedBox {
  width: 400px;
  height: 210px;
  margin: 35px auto;
  cursor: pointer;
  text-align: center;
  border-radius: 10px !important;
  border: 2px dashed #042E6B !important;
  background: #E1E5ED !important;
  padding: 20px;
  position: relative;
}

.uploadedIcon {
  position: absolute;
  top: 19px;
  right: 32px;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.uploadContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 210px;
  align-items: center;
  position: relative;
}

.uploadContentBlock {
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 13px;
  height: calc(100% - 14px);
}

.uploadContentTitle {
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 13px;
}

.uploadContentComment {
  height: 28px;
  color: #042E6B;
  text-align: center;
  font-size: 10px;
}

.uploadContentText {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 10px;
}

.imageError [class^="ant-upload"] {
  border-color: red !important;
}

.bordered {
  border-color: rgba(0, 42, 119, 0.1)!important;
}

.block {
  display: flex;
  gap: 60px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 50%;
}

.block {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.phone {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.phoneInput {
  width: 100%;

  & [class~="ant-input"] {
    border: none !important;
    border-bottom: 1px solid rgb(229 233 242) !important;
    padding-left: 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.date {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.label {
  min-width: 100px;
}

.dateInput {
  width: 100%;

  & [class~="ant-picker"] {
    border: none !important;
  }

  & [class~="ant-picker-focused"] {
    box-shadow: none !important;
  }
}