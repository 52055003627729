.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
    min-width: 50px;
    max-width: 50px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-type"],
.grid table[class~="MuiTable-root"] td[class~="column-type"],
.grid table[class~="MuiTable-root"] th[class~="column-isSuperadmin"],
.grid table[class~="MuiTable-root"] td[class~="column-isSuperadmin"],
.grid table[class~="MuiTable-root"] th[class~="column-isEnabled"],
.grid table[class~="MuiTable-root"] td[class~="column-isEnabled"],
.grid table[class~="MuiTable-root"] th[class~="column-registeredAt"],
.grid table[class~="MuiTable-root"] td[class~="column-registeredAt"],
.grid table[class~="MuiTable-root"] th[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] td[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] th[class~="column-login"],
.grid table[class~="MuiTable-root"] td[class~="column-login"] {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-phone"],
.grid table[class~="MuiTable-root"] td[class~="column-phone"],
.grid table[class~="MuiTable-root"] th[class~="column-email"],
.grid table[class~="MuiTable-root"] td[class~="column-email"] {
    min-width: 140px;
    max-width: 140px;
    text-align: center;
}

.grid [class~="manager-btn-wrap"] {
    justify-content: center;
}

.refSelect, [data-source="buyerId"] {
    width: 226px !important;
}
@supports (-webkit-hyphens: none) {
    [data-source="buyerId"] {
        width: 226px !important;
    }
}
[data-source="buyerId"] [class*="MuiTextField-root"]::after {
    display: none !important;
}
[data-source="buyerId"] [class*="MuiInputAdornment-root"] > span {
    position: absolute!important;
    right: 10px!important;
}
