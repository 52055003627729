.wrap {
    max-width: 100%;
}
.cardBlur {
    position: relative;
}
.contentBlur {
    filter: blur(3px);
}
.modalContent {
    position: absolute;
    width: 385px;
    height: 136px;
    border-radius: 8px;
    border: 3px solid #042E6B;
    background: #FFF;
    color: #000;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    top: calc(50% + 48px);
    left: calc(50% - 192px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 70px;
}
.title {
    margin-right: 10px;
}
.top {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 6px;
}
.checkbox {
    [class~="ant-checkbox-checked"] [class~="ant-checkbox-inner"] {
        background-color: #042E6B!important;
        border-color: #042E6B!important;
    }
}
.newIcon {
    border-radius: 4px;
    background: #042E6B;
    color: #FFF;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 1px 4px;
}

.tabHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.tabHeaderTitle {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}
.tabHeaderCnt {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: red;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    left: -14px;
    top: -4px;
    font-size: 11px;
    line-height: 11px;
    color: white;
}
.tabHeaderCntActive {
    display: flex;
}
.tabHeaderDate {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
}
.tabCloseIcon {
    color: #d9d9d9;
    font-size: 14px!important;
}

.tabs {
    [class~="ant-tabs-nav"] {
        margin: 0!important;
    }
    [class~="ant-tabs-nav"]::before {
        border: none;
    }
    [class~="ant-tabs-content-holder"] {
        border: 1px solid #042E6B;
        border-radius: 0 0 8px 8px;
        padding: 12px 24px 6px;
    }
    [class~="ant-tabs-tab"], [class~="ant-tabs-nav-add"] {
        position: relative!important;
        border: 1px solid #042E6B!important;
        border-bottom: none!important;
    }
    [class~="ant-tabs-tab-active"] {
        background-color: #042E6B !important;

        .tabHeaderCnt, .tabHeaderDate, .tabHeaderTitle {
            color: white !important;
        }
    }
    [class~="ant-tabs-tab"]:first-child {
        border-radius: 8px 0 0 0!important;
    }
    [class~="ant-tabs-tab-remove"] {
        padding: 0!important;
        margin: 0!important;
        border-radius: 50%!important;
        width: 12px!important;
        height: 12px!important;
        position: absolute;
        right: 3px;
        top: 0;
    }
}
.filesRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}
.filesCol {
    flex: 0 0 128px;
    height: 160px;
}
.filesBottom {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: right;
}
.uploadBtn {
    height: 160px;
}
.filePreview {
    [class~="fc-file"] {
        min-width: 128px;
        max-width: 128px;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    [class~="fc-pdf-box"] {
        border: none;
    }
    [class~="pi-link"] {
        right: -15px !important;
        bottom: -30px !important;
    }
    [class~="fc-img"] {
        max-height: 156px;
        max-width: 122px;
        margin-top: 6px;
    }
}

.filesTop {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-bottom: 12px;
}
.dateField {
    border: none!important;
}
.dateField input {
    color: rgba(0,0,0,.45) !important;
    color: #000;
    font-family: Outfit,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0!important;
}
.saveBtn {
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    width: 28px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    &.saveDisabled {
        cursor: not-allowed;
        background: rgba(4, 46, 107, 0.6);
        &:hover {
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }
}
.loader [class*="ant-spin-dot-item"] {
    background-color: #fff;
}
.loader [class*="ant-spin-dot-spin"] {
    top: 2px;
}
