.cardWrapper {
  min-height: 301px;
}
.cardWrapper:not(:hover) {
  border-color: #042E6B!important;
}

.cardWrapper:hover .headerIcon {
  color: #40a9ff;
}

.box {
  padding-top: 15px;
  font-family: Outfit, sans-serif;
  color: #000;
}

.headerIcon {
  color: #042E6B;
  font-size: 28px;
}

.mainInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  padding-right: 10px;
  transform: translateX(-10px);
}

.info {
  padding-top: 10px;
  padding-left: 10px;
}

.box .userLink {
  color: #000;
}

.box .userLink:hover {
  color: #40a9ff;
}

.creditCode {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.creditAccredited {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.copyIcon {
  font-size: 18px;
  margin-left: 10px;
}

.userName {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.userState {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.balanceText {
  margin-top: 15px;
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.balanceValue {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.balanceValueText {
  color: #000;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.financedCrop {
  margin-top: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.cropsIcon {
  color: #042E6B;
  font-size: 18px;
  margin-right: 4px;
}

.status {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-content: center;
}

.statusChip {
  width: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.contacts .contact {
  margin: 0 5px;
}

.sendToCredit {
  appearance: none;
  border: none;
  border-radius: 25px;
  padding: 4px 8px 4px 20px;
  min-width: 100px;
  color: #FFF;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  background: grey;
}

.sendToCreditColored {
  background: linear-gradient(270deg, #FD8A32 0%, #042E6B 52.46%);
}

/*PAR*/
