.wrap {
    color: #000;
    width: 306px;
    height: 59px;
    display: flex;
    position: relative;
    font-size: 16px;
    align-items: center;
    font-weight: 400;
    border-radius: 5px;
    justify-content: center;

    &.wrapValid {
        border: 5px solid #042E6B;
    }
    &.wrapInvalid {
        border: 5px solid #d32f2f;
    }
}

.title {
    position: absolute;
    background-color: white;
    top: -16px;
    left: -5px;
    padding-right: 6px;
}

.row {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}
.verificationIconValid {
    color: #042E6B;
}
.verificationIconInvalid {
    color: #d32f2f;
}
.qtyCol {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
}

.ttList {
    margin: 0;
    padding: 0 0 0 14px;
}
