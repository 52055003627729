.status {
    color: white;
    height: 20px;
    font-size: 11px;
    width: 100%;
    background-color: #74706B;
    &[class*="Applying"] {background-color: #2196F3;}
    &[class*="Completed_File"] {background-color: #213df3;}
    &[class*="Analysis"] {background-color: #009688;}
    &[class*="Approved"] {background-color: #4CAF50;}
    &[class*="Denied"] {background-color: #FF5722;}
    &[class*="Signed"] {background-color: #4CAF50;}
    &[class*="Repaid"] {background-color: #3F51B5;}
    &[class*="Restructure"] {background-color: #673AB7;}
    &[class*="Delay"] {background-color: #CDDC39;}
}

/* Bulk styles */
.box {}
.group {
    margin-top: 15px;
}
.title {
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    display: flex;
}
.titleIcon {
    height: 0;
    margin-top: 10px;
}
.select {
    margin-top: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E0E7EE;
    background-color: #F3F7F9;
    [class*="ant-select-selector"] {
        background-color: transparent !important;
    }
}
.content {
    margin-top: 15px;
}
.controls {
    margin-top: 20px;
    display: flex;
    column-gap: 12px;
    justify-content: center;
    align-items: center;
}
.btn {
    margin: 0 7px;
    padding: 5px 29px !important;
    height: 34px;
    border-radius: 8px;
    text-transform: none;
}
.textarea {
    margin-top: 10px;
    padding: 10px 19px 12px;
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    input {
        background: #E1E5ED;
    }
    [class*="ant-picker-input"] {
        padding-left: 10px;
        padding-right: 10px;
        background: #E1E5ED;
        border-radius: 8px;
        border: 1px solid #042E6B;
    }
}
.inputIcon {
    color: #333;
    margin-right: 10px;
}
