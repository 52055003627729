.container {
}
.documentsInfo {
  font-size: 20px;
  font-style: italic;
  line-height: 20px;
}
.documents {
  padding: 26px 6px 26px 32px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 8px 8px 8px;
  border: 1px solid #042E6B;
  background: #FFF;
}
.document {
  width: calc(50% - 25px);
  margin-right: 25px;
  margin-bottom: 25px;
  padding: 0 20px 18px;
  border-radius: 10px;
  border: 1px solid #042E6B;
  background: #FFF;
}
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tabs {
  border: 1px solid #042E6B;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  background: #FFF;
}
/* indicator */
.tabs > div > span {
  display: none;
}
.tabs .tab {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-right: 1px solid #042E6B;
  text-transform: none;
  padding: 16px 20px 14px 30px;
}
.tabs .tab:last-child {
  border-right: none;
}

.tabs .tabSelected {
  color: #FFF !important;
  background: #042E6B;
}

.tabs .tabLabel {
  display: flex;
}

.tabs .tabCounter {
  font-size: 16px;
  margin-left: 15px;
  padding: 0 10px;
  height: 26px;
  border-radius: 9px;
  color: #042E6B;
  background-color: #E1E5ED;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs .tabSelected .tabCounter {
  color: #042E6B;
  background-color: white;
}

.tabLockIcon {
  position: absolute;
  top: 0px;
  left: 0px;
}

.downloadBtn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 16px 22px !important;
  height: 50px !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: #042E6B !important;
  border: 1px solid #042E6B !important;
  font-weight: 400 !important;
  font-family: Outfit, sans-serif;
  font-size: 14px !important;
  gap: 4px;
}
.downloadBtn, .downloadBtn:hover, .downloadBtn:active, .downloadBtn:visited, .downloadBtn:focus {
  background-color: white !important;
}
.downloadBtnIcon {
  display: flex;
  width: 28px;
  min-width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.reports {
  width: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
