.content {
  margin-top: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
}

.formRow {
  display: flex;
  width: 100%;
  gap: 20px;
}

.formCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.formLabel {
  font-size: 15px;
  font-weight: 500;
}

.uploadedBox {
  margin: 10px auto 0;
  width: 350px;
  height: 170px;
  border-radius: 10px !important;
  border: 2px dashed #042E6B !important;
  background: #E1E5ED !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.uploadedBox img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.uploadBox {
  margin: 10px auto 0;
  width: 350px;
  height: 170px;
  display: block;
  cursor: pointer;
}

.removeImg svg {
  position: absolute;
  right: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

.removeImg {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
}

.removeImg::after {
  display: none;
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, .55);
  box-shadow: 0 0 5px rgba(255, 255, 255, .7);
  z-index: 1;
}

.removeImg:hover::after {
  display: block;
}

.actions {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.save {
  border-radius: 8px !important;
  border: 1px solid #042E6B !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #042E6B !important;
  text-transform: initial !important;
  padding: 8px 69px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: auto !important;
}

.delete {
  border: 1px solid #D72B2C !important;
  border-radius: 8px !important;
  background: transparent !important;
  color: #D72B2C !important;
  text-align: center !important;
  font-family: Outfit, sans-serif !important;
  font-size: 14px !important;
  padding: 10px 69px !important;
  text-transform: initial !important;
  box-shadow: none !important;
}

.error {
  border-bottom: 1px solid red !important;
}

.uploadContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 170px;
  align-items: center;
  position: relative;
}

.uploadContentBlock {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 13px;
  height: calc(100% - 14px);
}

.uploadContentTitle {
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 13px;
}

.uploadContentComment {
  height: 28px;
  color: #042E6B;
  text-align: center;
  font-size: 10px;
}
