.wrap {
  margin-top: 20px;
  font-family: Outfit, sans-serif;
}

.formItem {
  margin-bottom: 0 !important;
}

.info {
  margin: 18px 8px;
  padding: 10px 12px 10px 18px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #E1E5ED;
}

.title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.farms {
  margin-left: 10px;
}

.form {
  margin-top: 18px;
}

.form input {
  padding: 5px 16px 5px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
}

.form .formBtn {
  text-transform: none;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 34px;
}

.tabs {
  margin-top: 15px;
}

.tab {
  position: relative;
  margin-top: -12px;
  margin-bottom: -12px;
  padding: 5px 40px 5px 26px;
  background-color: #DFECF9 !important;
  border-left: 1px solid rgba(76, 78, 100, 0.25);
  border-top: 1px solid rgba(76, 78, 100, 0.25);
}

.tab.tabCompact {
  padding: 11px 11px 11px 11px;
}

.tab.tabCompact .tabName {
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 300;
}

.tab.tabCompact .btnRemove {
  top: -8px;
  margin-top: 0;
  right: -8px;
}

.tab.tabFirst {
  border-radius: 8px 0 0 0 !important;
}
.tab.tabLast {
  border-right: 1px solid rgba(76, 78, 100, 0.25);
}

.tab .btnRemove {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}

.tab .btnPlus {
  position: absolute;
  top: 0;
  right: -36px;
  width: 36px;
  height: 100%;
  margin-top: -1px;
  background-color: #FD8A32;
  border: 1px solid #FD8A32;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  padding: 0;
  border-radius: 0 8px 0 0;
  color: white;
}

.tab .tabName {
  color: #292929;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
}
