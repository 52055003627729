.box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #042E6B;
    border-radius: 5px;
    width: 306px;
    height: 59px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
.title {
    position: absolute;
    background-color: white;
    top: -16px;
    left: -5px;
    padding-right: 6px;
}
.value {
    font-size: 18px;
}
