.cardWrapper {
    min-height: 200px;
}
.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}
.cardWrapper [class~="InitialCard-left"] {
    font-size: 16px;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}
.wrap {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download, .update {
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-family: Outfit,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    }
}
.update {
    justify-content: flex-end;
    padding-bottom: 4px;
}
.updateBottom {
    color: #FFF;
    font-family: Outfit,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
}

.modalWrap {
    background: none;
}
.modalWrap [class~="ant-modal-close"] {
    display: none;
}
.modalWrap [class~="ant-modal-content"] {
    background: none;
    border: none;
    box-shadow: none;
    min-width: 758px;
}
.modal {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.modalHeader {
    background: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 8px;
    border: 2px solid #042E6B;
    border-radius: 4px;
}
.modalHeaderDate {
    display: flex;
    gap: 10px;
    padding-left: 32px;
}
.modalHeaderAuthor {
    margin-left: 60px;
    display: flex;
    gap: 10px;
}
.modalHeaderActions {
    margin-left: auto;
    display: flex;
    gap: 8px;
    position: relative;
    top: 4px;
}
.modalHeaderValue {
    opacity: 0.45;
}
.modalContent {
    background: #fff;
    min-height: 75dvh;
    max-height: 880px;
    overflow-y: auto;
    display: flex;
    align-content: center;
    justify-content: center;
}
.modalDownload {
    cursor: pointer;
}
.modalClose {
    background: red;
    width: 28px;
    height: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
.bottom {
    cursor: pointer;
}
.disabled {
    cursor: default;

    svg {
        g rect {
            fill: grey;
        }
    }
}