.checkWrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.check {
    font-size: 24px;

    &.unchecked {
        color: rgb(253, 73, 45);
    }
    &.checked {
        color: rgb(136, 210, 86);
    }
}
.link {
    cursor: pointer;
    color: #042E6B;
    &:hover {
        text-decoration: underline;
    }
}
