.select {
  width: 100%;
}

.btnChange {
  height: 40px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.btnChange svg {
  margin-left: 10px;
}

.btnChangeText {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  line-height: normal;
}

.lockIcon {
  font-size: 18px;
  color: #000;
}
