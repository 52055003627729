.wrap [class~="InitialCard-left"] {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 22px);
}

.cardTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.cardTitle:not(:hover) span {
    color: #042E6B!important;
}

.title {
    margin-top: 12px;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.link {
    background-color: #E1E5ED;
    border-radius: 26px;
}
.link span {
    font-size: 14px;
}

.top {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.info {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.infoRow {
    flex: 1;
}
.infoRow:first-child {
    border-right: 1px solid rgba(4, 46, 107, 0.50);
    padding-right: 10px;
    margin-right: 10px;
}
.infoItem {
    display: inline-block;
    width: 100%;
}

.footer {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}
.footer * {
    margin: 0 3px;
}
