.farm {
    display: flex;
    align-content: flex-start;
    padding-top: 24px;
}

.head {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    margin-top: -20px;
    width: 100%;
    gap: 20px;
    position: relative;
    z-index: 1;
}

.code {
    margin-right: 40px;

    & span {
        font-weight: 500;
        margin-right: 8px;
    }
}

.file {
    width: 200px;
    max-width: 200px;

    & [class~="ant-upload-picture-card-wrapper"] {
        height: 250px;
    }
}

.info {
    width: calc(100% - 200px);
    display: flex;
    align-content: flex-start;
    gap: 40px;
    padding-left: 40px;
}

.col {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.property {
    width: 100%;
    display: flex;
    align-content: flex-start;
}

.label {
    width: 40%;
    min-width: 40%;
    display: flex;
    align-items: center;
}

.value {
    width: 60%;
    height: 32px;
    display: flex;
    align-items: center;

    & span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    & > div {
        width: 100%;
    }

    & [class~="ant-input"] {
        width: 100%;
        border: none;
        border-bottom: 1px solid rgb(128, 128, 128) !important;
        border-radius: 0;
        box-shadow: none !important;
        padding-left: 0 !important;
    }

    & .select {
        border: none;
    }

    & [class~="ant-picker"] {
        border: none !important;
    }

    & [class~="ant-picker-focused"] {
        box-shadow: none !important;
    }

    & [class~="ant-select"] {
        border-radius: 0;
    }

    & [class~="ant-select-selector"] {
        box-shadow: none !important;
        border: none !important;
        border-bottom: 1px solid rgb(128, 128, 128) !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
    }

    & [class~="ant-input:focus"], & [class~="ant-input-focused"] {
        box-shadow: none !important;
    }
}

.coordinates {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    & [class~="ant-input"] {
        box-shadow: none !important;
        width: 100%;
        border: none;
        border-bottom: 1px solid rgb(128, 128, 128) !important;
        border-radius: 0;
        padding-left: 0 !important;
    }

    & [class~="ant-input:focus"], & [class~="ant-input-focused"] {
        box-shadow: none !important;
    }
}
.filePreview {
    & > div {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.download {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }
}

.save {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }
}

.remove {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #FF0000;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }

    & button {
        border: none;
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
}

.prev {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: #fff;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }
}

.next {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: #fff;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }
}

.add {
    appearance: none;
    width: 28px;
    height: 20px;
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: #fff;

    &[disabled] {
        opacity: 0.45;
        cursor: default;
    }
}

.bottom {
    margin-top: 60px;
    min-height: 300px;
}

.bottomHead {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 16px;
}

.image {
    border-radius: 10px;
    border: 2px dashed #042E6B;
    background: #E1E5ED;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;

    &:hover {
        cursor: pointer;
        background: #d2d6dd;
    }

    & span {
        color: #042E6B;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.bottom {
    margin-top: 60px;
    min-height: 300px;
}

.bottomHead {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 16px;
}

.image {
    border-radius: 10px;
    border: 2px dashed #042E6B;
    background: #E1E5ED;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        color: #042E6B;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.map {
    aspect-ratio: 6 / 3;

    & > div {
        height: 100%;
    }
}

.picture {
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    aspect-ratio: 6 / 3;

    & > div {
        overflow: hidden;

        & > div {
            width: 100%;

            & > div:first-child {
                width: 100%;
                height: 100%;

                & > div {
                    width: 100%;
                    height: 100%;

                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    & [class~="ant-upload-picture-card-wrapper"] {
        height: 100%;
    }
}