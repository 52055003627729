.indicators {
    margin-top: 20px;
}
.indicatorsCol {
    display: flex;
    justify-content: center;
}
.box {
    font-family: Outfit, sans-serif;
    color: #272727;
    max-width: 100%;

    [class~="MuiFormHelperText-root"] {
        position: absolute;
        top: 22px;
    }
}
.leftBox {
    border-radius: 42px 8px 8px 8px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    padding: 0 10px 22px 30px;
    position: relative;
}
.header {
    padding-left: 70px;
}
.avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    overflow: hidden;
}
.avatarImg {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.buyerSwitcher {
    margin-top: 10px;
}
.suggetsInput {
    color: #000;
    font-size: 16px !important;
    font-weight: 400;
    line-height: normal;

    & > [class*="ant-select-selector"] {
        padding: 0 !important;
        background-color: transparent !important;
        border: none !important;
        border-bottom: 1px solid rgba(0, 42, 119, 0.10) !important;
        box-shadow: none !important;
    }
}
.rightCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.link {
    color: #000;
}
.activityRowHalf {
    display: flex;
    align-items: center;
}
.activityBlock {
    display: flex;
    flex-wrap: wrap;
}
.reason {
    width: 100%;
    @media screen and (min-width: 1600px) {
        flex: 0 0 60%;
        max-width: 60%;
    }
    @media screen and (min-width: 1800px) {
        flex: 1;
        max-width: initial;
    }
}
.reasonContentFull {
    margin-top: 10px;
}
.rightActions {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
}
.resend {
    display: flex;
    gap: 8px;
    align-items: center;
    align-content: center;
    height: 32px;
    margin-bottom: -24px;

    & > button {
        border-radius: 4px;
    }
}
.tooltipIcon {
    color: #AAA;
}
